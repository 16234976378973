// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import themeConfig from '@configs/themeConfig'
// ** Axios Imports
import axios from 'axios'

export const getDataQuote = createAsyncThunk('getOrderList/getDataQuote', async params => {
  //console.log('Data Fetch from the ::: Order LIST')
  params['token'] = themeConfig.APILinks.token
  // params['Customer_ID'] = '4659'
  params['site_id'] = JSON.parse(localStorage.getItem('site_id'))
  params['job_status'] = 0

  const response = await axios.post(themeConfig.APILinks.getClientCompanyQuotes, params)
  //console.log(response)
  return { allData: response.data.allData, data: response.data.client, totalPages: response.data.client.total, params  }
})

// export let data

export const datatablesSlice2 = createSlice({
  name: 'getOrderList',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getDataQuote.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
    })
  }
})

export default datatablesSlice2.reducer