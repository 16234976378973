// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Config
import themeConfig from '@configs/themeConfig'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('BrandingDaysMapping/getData', async params => {
  console.log('Data Fetch from the ::: AMPM Branding Days Mapping')
  
  params['token'] = themeConfig.APILinks.producttoken
  //console.log(params)
  const response = await axios.post(themeConfig.APILinks.deliverydaysList, params)
  //console.log(response)
  return { days_list: response.data.days_list, total: response.data.total, params }
})

export let data

export const datatablesSlice = createSlice({
  name: 'BrandingDaysMapping',
  initialState: {
    days_list: [],
    total: 1,
    params: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.days_list = action.payload.days_list
      state.params = action.payload.params
      state.total = action.payload.total
    })
  }
})

export default datatablesSlice.reducer
