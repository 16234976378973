// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Config
import themeConfig from '@configs/themeConfig'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('productList/getData', async params => {
  console.log('Data Fetch from the ::: PRODUCT LIST')

  params['token'] = themeConfig.APILinks.producttoken
  const response = await axios.post(themeConfig.APILinks.productlist, params)
  return { products: response.data.products, all_products: response.data.all_products, supplier_category: response.data.supplier_category, total: response.data.total, params }
})

export let data

export const datatablesSlice = createSlice({
  name: 'productList',
  initialState: {
    products: [],
    all_products: [],
    supplier_category: [],
    total: 1,
    params: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.products = action.payload.products
      state.allproducts = action.payload.all_products
      state.supplier_category = action.payload.supplier_category
      state.params = action.payload.params
      state.total = action.payload.total
    })
  }
})

export default datatablesSlice.reducer
