// You can customize the template with the help of this file

//Template config options
const themeConfig = {

  app: {
    site_id: JSON.parse(localStorage.getItem('site_id')),
    appName: 'FINANCE',
    appLogoImage: require('@src/assets/images/logo/AMPM-Logo.jpg').default,
    appLogoImagePng: require('@src/assets/images/logo/AMPM-Logo.png').default,
   /* ampmPromo: require('@src/assets/images/logo/ampmPromo.png').default,
    buyAu: require('@src/assets/images/logo/buyAu.png').default,
    buyNz: require('@src/assets/images/logo/buyNz.png').default,*/
    ampmPromo: require('@src/assets/images/logo/ampm-black.png').default,
    buyAu: require('@src/assets/images/logo/buypromotional-au-logo-white.png').default,
    ampmPromoAU: require('@src/assets/images/logo/AMPM-Promo-Logo-HOR.png').default,
    buyNz: require('@src/assets/images/logo/1buy-promotional-white.png').default,
    placeholder: require('@src/assets/images/logo/placeholder_image.png').default,
    multiColourImage: require('@src/assets/images/logo/multi-clours.jpg').default,
    mailerliteLogo: require('@src/assets/images/logo/mailerlite-logo.png').default,
    GoogleAdsLogo: require('@src/assets/images/logo/Google-Ads-Logo.png').default,
    FacebookLogo: require('@src/assets/images/logo/Facebook-Logo.png').default,
    unlimitedLogo: require('@src/assets/images/logo/unlimited.png').default,
    full_colour_logo: require('@src/assets/images/logo/full-color-icon.png').default,
    //unlimitedLogo: require('@src/assets/images/logo/world-shipping-icon.png').default,
    pporder_siteurl:'https://ppordermanager.com'
  },
  icons:{
    ai_file: require('@src/assets/images/icons/file-icons/AI.png').default,
    eps_file: require('@src/assets/images/icons/file-icons/EPS.png').default,
    pdf_file: require('@src/assets/images/icons/file-icons/PDF.png').default,
    psd_file: require('@src/assets/images/icons/file-icons/psd.png').default,
    doc_file: require('@src/assets/images/icons/file-icons/doc.png').default,
    docx_file: require('@src/assets/images/icons/file-icons/doc.png').default
  },
  suppllierLogo: {
    supplier1: require('@src/assets/images/logo/trends_suppllier.png').default,
    supplier2: require('@src/assets/images/logo/logoline_suppllier.png').default,
    supplier3: require('@src/assets/images/logo/orso_suppllier.png').default,
    supplier4: require('@src/assets/images/logo/fashionsbiz_suppllier.png').default,
    supplier5: require('@src/assets/images/logo/jb_wear_suppllier.jpg').default,
    supplier6: require('@src/assets/images/logo/legend_life_suppllier.jpeg').default,
    supplier7: require('@src/assets/images/logo/premium_apparel_suppllier.jpg').default,
    supplier8: require('@src/assets/images/logo/cforce_suppllier.png').default,
    supplier9: require('@src/assets/images/logo/The-Catalogue-logo.png').default,
    supplier10: require('@src/assets/images/logo/ascolour-dot-logo-white.png').default
  },
  APILinks: {
    token: 'A5HFstrolor3upoitxdsqzwod8t7U',
    clienttoken: 'A5HFstrolor3upoitxdsqzwod8t7U',
    producttoken: 'A5HFstrolor3upoitxfddsqvsjhkissft7U',
    studiotoken: 'A5HFstrolor3upoitxfddsqvsjhkissft7U',
    studioMockuptoken: 'Oz8knQ81pyK3etdeddyut',
    companytoken: 'A5HFstrolor3upoitxdsqzwod8t7U',
    getXeroAmount: 'https://financeapi.ampmhub.com/getXeroAmount',
    trendsIndex: 'https://financeapi.ampmhub.com/trendsIndex',
    financeDetailsIndex: 'https://financeapi.ampmhub.com/financeDetailsIndex',
    dashboardData: 'https://financeapi.ampmhub.com/dashboardData',
    getCompanyName: 'https://financeapi.ampmhub.com/getCompanyName?token=A5HFstrolor3upoitxdsqzwod8t7U',
    logDetailsIndex: 'https://financeapi.ampmhub.com/logDetailsIndex',
    getTrendsStatus: 'https://financeapi.ampmhub.com/getTrendsStatus?token=A5HFstrolor3upoitxdsqzwod8t7U',
    trendPoStore: 'https://financeapi.ampmhub.com/trendPoStore',
    syncTrends: 'https://financeapi.ampmhub.com/sync?token=A5HFstrolor3upoitxdsqzwod8t7U',

    // ClientManeger
    getClient: 'https://clientapi.ampmhub.com/postClient',
    duplicate_client: 'https://clientapi.ampmhub.com/post_duplicate_client',
    client_merge: 'https://clientapi.ampmhub.com/client/same/merge',
    getClientCompanyName: 'https://clientapi.ampmhub.com/getCompanyName?token=A5HFstrolor3upoitxdsqzwod8t7U',
    getCompany: 'https://clientapi.ampmhub.com/postCompany',
    getTerm: 'https://clientapi.ampmhub.com/postPayment_term',    
    storeClient: 'https://clientapi.ampmhub.com/storeClient',
    storeCompany: 'https://clientapi.ampmhub.com/storeCompany',
    deleteClient: 'https://clientapi.ampmhub.com/deleteClient',
    findClientInQuotes: 'https://clientapi.ampmhub.com/findClientInQuotes',
    deleteCompany: 'https://clientapi.ampmhub.com/deleteCompany',
    storeGroup: 'https://clientapi.ampmhub.com/storeGroup',
    updateClient: 'https://clientapi.ampmhub.com/updateClient',
    showClient: 'https://clientapi.ampmhub.com/showClient',
    updateCompany: 'https://clientapi.ampmhub.com/updateCompany',
    update_company: 'https://clientapi.ampmhub.com/update_company',
    update_co_account_status: 'https://clientapi.ampmhub.com/update_co_account_status',

    getGroup: 'https://clientapi.ampmhub.com/postGroup',

    financeDetailsUpdate: 'https://financeapi.ampmhub.com/financeDetailsUpdateOrCreate?token=A5HFstrolor3upoitxdsqzwod8t7U',

    updateGroup: 'https://clientapi.ampmhub.com/updateGroup',
    deleteGroup: 'https://clientapi.ampmhub.com/deleteGroup',
    findCompanyGroup: 'https://clientapi.ampmhub.com/findCompanyGroup',
    showGroup: 'https://clientapi.ampmhub.com/showGroup',
    showCompany: 'https://clientapi.ampmhub.com/showCompany',
    storeAddress: 'https://clientapi.ampmhub.com/storeAddress',
    showAddress: 'https://clientapi.ampmhub.com/showAddress',
    updateAddress: 'https://clientapi.ampmhub.com/updateAddress',
    defaultAddress: 'https://clientapi.ampmhub.com/defaultAddress',
    deleteAddress: 'https://clientapi.ampmhub.com/deleteAddress',
    sameAsBillingAddress: 'https://clientapi.ampmhub.com/sameAsBillingAddress',

    overrideIsuueStatusUpdate: 'https://financeapi.ampmhub.com/overrideIsuueStatusUpdate',

    blockClient: 'https://clientapi.ampmhub.com/blockClient',

    send_client_job_average_data: 'https://ppordermanager.com/?action=send_client_job_average_data',
    send_job_data_to_laravel: 'https://ppordermanager.com/?action=send_job_data_to_laravel',

    sendJobDataToLaravel: 'https://clientapi.ampmhub.com/sendJobDataToLaravel',
    getClientCompanyQuotes: 'https://clientapi.ampmhub.com/getClientCompanyQuotes',
    getClientCompanyDetails: 'https://clientapi.ampmhub.com/getClientCompanyDetails',
    getStaffOptions: 'https://clientapi.ampmhub.com/getStaffOptions?token=A5HFstrolor3upoitxdsqzwod8t7U',
    storeNote: 'https://clientapi.ampmhub.com/storeNote',
    storeFolloupNote: 'https://clientapi.ampmhub.com/storeFolloupNote',
    showNote: 'https://clientapi.ampmhub.com/showNote',
    showNoteTask: 'https://clientapi.ampmhub.com/showNoteTask',
    deleteNote: 'https://clientapi.ampmhub.com/deleteNote',
    updateNoteAction: 'https://clientapi.ampmhub.com/updateNoteAction',
    updateNoteStatus: 'https://clientapi.ampmhub.com/updateNoteStatus',
    updateNote: 'https://clientapi.ampmhub.com/updateNote',
    getCustomerOfCompany: 'https://clientapi.ampmhub.com/getCustomerOfCompany',
    storeTags: 'https://clientapi.ampmhub.com/storeTags',
    getTagName: 'https://clientapi.ampmhub.com/getTagName?token=A5HFstrolor3upoitxdsqzwod8t7U',
    storeTagsRelation: 'https://clientapi.ampmhub.com/storeTagsRelation',
    tagNameWithParent: 'https://clientapi.ampmhub.com/tagNameWithParent?token=A5HFstrolor3upoitxdsqzwod8t7U',
    getCustomerOfCompanyActivity: 'https://clientapi.ampmhub.com/getCustomerOfCompanyActivity',
    showNoteTaskActivity: 'https://clientapi.ampmhub.com/showNoteTaskActivity',
    getGroupName: 'https://clientapi.ampmhub.com/getGroupName',
    showTask: 'https://clientapi.ampmhub.com/showTask',
    CurrentUserTask: 'https://clientapi.ampmhub.com/CurrentUserTask',

    add_delete_customer_actions: 'https://clientapi.ampmhub.com/customers/actions',
    get_customer_actions: 'https://clientapi.ampmhub.com/customers/actions/list',

    upload_client_company_doc: 'https://clientapi.ampmhub.com/upload/documents/client_company',
    delete_client_company_doc: 'https://clientapi.ampmhub.com/delete/documents/client_company',
    get_client_company_doc: 'https://clientapi.ampmhub.com/get/documents/client_company',
    get_client_company_doc_folder: 'https://clientapi.ampmhub.com/get/documents/client_company/folder',
    add_client_company_doc_folder: 'https://clientapi.ampmhub.com/add/documents/client_company/folder',
    merge_client: 'https://clientapi.ampmhub.com/client/merge',
    subscribers_list: 'https://clientapi.ampmhub.com/subscribers',
    single_subscriber: 'https://clientapi.ampmhub.com/subscribers/single',
    subscribers_groups: 'https://clientapi.ampmhub.com/subscribers/groups',
    get_groups_belongs_subscriber: 'https://clientapi.ampmhub.com/subscribers/groups/single',
    assign_subscriber_to_groups: 'https://clientapi.ampmhub.com/subscribers/groups/single/assign',
    assign_group_to_subscibers_bulk: 'https://clientapi.ampmhub.com/subscribers/groups/bulk/assign',
    subscriber_activity: 'https://clientapi.ampmhub.com/subscribers/activity',
    sync_subscriber_activity: 'https://clientapi.ampmhub.com/subscribers/sync/activity',
    subscibe_unsubscibe_client: 'https://clientapi.ampmhub.com/subscribers/action/type',
    add_customers_rules: 'https://clientapi.ampmhub.com/customers/rules/add',
    get_customers_rule: 'https://clientapi.ampmhub.com/customers/rules/single',
    get_customers_rules: 'https://clientapi.ampmhub.com/customers/rules',
    update_customers_rules: 'https://clientapi.ampmhub.com/customers/rules/update',
    add_customers_rule_list: 'https://clientapi.ampmhub.com/customers/rules/list/add',
    get_customers_rule_list: 'https://clientapi.ampmhub.com/customers/rules/list',
    update_customers_rule_group: 'https://clientapi.ampmhub.com/customers/rules/list/update',
    delete_customers_rule_group: 'https://clientapi.ampmhub.com/customers/rules/list/delete',
    delete_customers_rule: 'https://clientapi.ampmhub.com/customers/rules/delete',
    add_delete_customer_rules_actions: 'https://clientapi.ampmhub.com/customers/rules/actions',
    sync_customers_rule_data: 'https://clientapi.ampmhub.com/customers/rules/sync',
    fire_customers_rule_actions_data: 'https://clientapi.ampmhub.com/customers/rules/call',
    reminders_list: 'https://clientapi.ampmhub.com/customers/reminders',
    reminders_add: 'https://clientapi.ampmhub.com/customers/reminders/add',
    reminders_delete: 'https://clientapi.ampmhub.com/customers/reminders/delete',

    productlist: 'https://productapi.ampmhub.com/products',
    product_permalink: 'https://productapi.ampmhub.com/products/permalink',
    product_image: 'https://productapi.ampmhub.com/products/image',
    product_disable: 'https://productapi.ampmhub.com/products/disable',
    products_supplier_cat: 'https://productapi.ampmhub.com//products/supplier/cat',
    productcats: 'https://productapi.ampmhub.com/supplierCategory',
    ampmParent: 'https://productapi.ampmhub.com/get_ampmcatname',
    updateAmpmcatname: 'https://productapi.ampmhub.com/get_update_ampmcatname',
    getampmcatnameList: 'https://productapi.ampmhub.com/get_ampmcatnameList',
    addAmpmCats: 'https://productapi.ampmhub.com/add_ampmcats',
    updateAmpmCat: 'https://productapi.ampmhub.com/update_ampmcat',
    updateAmpmcatOrders: 'https://productapi.ampmhub.com/update_ampmcat_orders',
    deleteAmpmCat: 'https://productapi.ampmhub.com/delete_ampmcat',
    ampmCats: 'https://productapi.ampmhub.com/get_ampmcat',
    userDetails: 'https://clientapi.ampmhub.com/userLoginDetails',
    storeProductTags: 'https://productapi.ampmhub.com/storeTags',
    ProductsTagsParent: 'https://productapi.ampmhub.com/parentTagsList',
    GetAmpmTag: 'https://productapi.ampmhub.com/getAmpmTag',
    parentTagsList: 'https://productapi.ampmhub.com/parentTagsList',
    updateAmpmtagname: 'https://productapi.ampmhub.com/get_update_ampmtagname',
    getampmtagnameList: 'https://productapi.ampmhub.com/get_ampmtagnameList',
    updateAmpmtags: 'https://productapi.ampmhub.com/update_ampmtags',
    deleteAmpmtags: 'https://productapi.ampmhub.com/delete_ampmtags',
    addAmpmMapping: 'https://productapi.ampmhub.com/add_supcat_mapping',
    addAmpmTagMapping: 'https://productapi.ampmhub.com/add_suptag_mapping',
    changeCatStatus: 'https://productapi.ampmhub.com/change_cat_status',
    categoriesDetails: 'https://productapi.ampmhub.com/categories_details',
    addProperties: 'https://productapi.ampmhub.com/addProperties',
    getProperties: 'https://productapi.ampmhub.com/get_properties',
    updateProperties: 'https://productapi.ampmhub.com/updateProperties',
    deleteProperty: 'https://productapi.ampmhub.com/deleteProperty',
    changePropertyStatus: 'https://productapi.ampmhub.com/changePropertyStatus',
    productSiteStatus: 'https://productapi.ampmhub.com/productSiteStatus',
    productSiteStatusDetails: 'https://productapi.ampmhub.com/productSiteStatusDetails',
    addManualCategoryProduct: 'https://productapi.ampmhub.com/addManualCategoryProduct',
    addManualTagProduct: 'https://productapi.ampmhub.com/addManualTagProduct',
    getManualCatTag: 'https://productapi.ampmhub.com/getManualCatTag',
    productGlobalCatTagStatus: 'https://productapi.ampmhub.com/productGlobalCatTagStatus',
    productGlobalActiveCatsTags: 'https://productapi.ampmhub.com/productGlobalActiveCatsTags',
    getSingleProductDetails: 'https://productapi.ampmhub.com/getSingleProductDetails',
    product_image_assign: 'https://productapi.ampmhub.com/product/images',
    changeImageCaption: 'https://productapi.ampmhub.com/changeImageCaption',
    changeImageColours: 'https://productapi.ampmhub.com/changeImageColours',
    changeImageStock: 'https://productapi.ampmhub.com/changeImageStock',
    changeImageStockNew: 'https://productapi.ampmhub.com/changeImageStockNew',
    getProductImageTypes: 'https://productapi.ampmhub.com/getProductImageTypes',
    getProductImagecolours: 'https://productapi.ampmhub.com/getProductImagecolours',
    getProductImageWithZeroTotal: 'https://productapi.ampmhub.com/getProductImageWithZeroTotal',
    getProductListWithZeroStatus: 'https://productapi.ampmhub.com/getProductListWithZeroStatus',
    change_product_check_manually: 'https://productapi.ampmhub.com/product/check/manually',
    ProductsDashboardDetails: 'https://productapi.ampmhub.com/ProductsDashboardDetails',
    getProductListWithForLinks: 'https://productapi.ampmhub.com/getProductListWithForLinks',
    productLinksWithParent: 'https://productapi.ampmhub.com/productLinksWithParent',
    productUnlinksWithParent: 'https://productapi.ampmhub.com/productUnlinksWithParent',
    publishThisProduct: 'https://productapi.ampmhub.com/publishThisProduct',
    reCheckThisProduct: 'https://productapi.ampmhub.com/reCheckThisProduct',
    productSettingAdd: 'https://productapi.ampmhub.com/productSettingAdd',
    productSettingGet: 'https://productapi.ampmhub.com/productSettingGet',
    productsColoursList: 'https://productapi.ampmhub.com/productsColoursList',
    mappingColourPropertiesToColours: 'https://productapi.ampmhub.com/mappingColourPropertiesToColours',
    mappingColourTotal: 'https://productapi.ampmhub.com/mappingColourTotal',
    getProductStocks: 'https://productapi.ampmhub.com/getProductStocks',
    productColoursImageWithStock: 'https://productapi.ampmhub.com/productColoursImageWithStock',
    addStockNameToImage: 'https://productapi.ampmhub.com/addStockNameToImage',
    assignStockColourTotal: 'https://productapi.ampmhub.com/assignStockColourTotal',
    getProductImageStock: 'https://productapi.ampmhub.com/getProductImageStock',
    getProductImageStockNew: 'https://productapi.ampmhub.com/getProductImageStockNew',
    addProductCommonStock: 'https://productapi.ampmhub.com/addProductCommonStock',
    getClientMailList: 'https://clientapi.ampmhub.com/getClientMailList',
    getsingleMaildetails: 'https://clientapi.ampmhub.com/getsingleMaildetails',
    sendMailToclient: 'https://clientapi.ampmhub.com/sendMailToclient',
    replyMailToclient: 'https://clientapi.ampmhub.com/replyMailToclient',
    forwardMailToclient: 'https://clientapi.ampmhub.com/forwardMailToclient',
    productBrandingList: 'https://productapi.ampmhub.com/productBrandingList',
    studioBrandingList: 'https://productapi.ampmhub.com/studioBrandingList',
    studioCategoryList: 'https://productapi.ampmhub.com/supplier_category_list',
    studioMockList: 'https://studioapi.ampmhub.com/v1/mockup/listing',
    SaveMockupReady: 'https://productapi.ampmhub.com/save_mockup_ready_branding',
    saveMockupImageStatus: 'https://productapi.ampmhub.com/save_mockup_image_status',
    printDescriptionsList: 'https://productapi.ampmhub.com/printDescriptionsList',
    brandAreaMapping: 'https://productapi.ampmhub.com/brandAreaMapping',
    maxColourMapping: 'https://productapi.ampmhub.com/maxColourMapping',
    brandAreaMappingBulk: 'https://productapi.ampmhub.com/brandAreaMappingBulk',
    maxColourBulkMapping: 'https://productapi.ampmhub.com/maxColourBulkMapping',
    productBrandingDetails: 'https://productapi.ampmhub.com/product/brands/single',
    productDeliveryDays: 'https://productapi.ampmhub.com/product/delivery/days',
    trendsDeliveryDays: 'https://productapi.ampmhub.com/trends/delivery/days',
    trendsDeliveryMapping: 'https://productapi.ampmhub.com/trends/delivery/mapping',
    trendsBrandingList: 'https://productapi.ampmhub.com/trends/delivery/settings',
    addPrintingMapping: 'https://productapi.ampmhub.com/settings/printing/add',
    brandingtypeList: 'https://productapi.ampmhub.com/brandingtype/list',
    deliverydaysList: 'https://productapi.ampmhub.com/deliverydays/list',
    brandingTypeMapping: 'https://productapi.ampmhub.com/brandingtype/mapping',
    brandingDaysMapping: 'https://productapi.ampmhub.com/brandingdays/mapping',
    productlogs: 'https://productapi.ampmhub.com/product/logs',
    productlog: 'https://productapi.ampmhub.com/product/logs/single',
    productlogstatus: 'https://productapi.ampmhub.com/product/logs/status',
    sites_add: 'https://productapi.ampmhub.com/settings/sites/add',
    sites_list: 'https://productapi.ampmhub.com/settings/sites',
    product_reset: 'https://productapi.ampmhub.com/product/reset',
    update_product_details: 'https://productapi.ampmhub.com/sites/product/update',
    save_images_changes: 'https://productapi.ampmhub.com/save_images_changes',
    product_properties_listing: 'https://productapi.ampmhub.com/product/properties',
    product_properties_mapping: 'https://productapi.ampmhub.com/product/properties/mapping',
    product_listing_for_mapping: 'https://productapi.ampmhub.com/product/properties/listing',
    product_quoteOnly: 'https://productapi.ampmhub.com/savequoteOnly',
    getProductMetaByKey: 'https://productapi.ampmhub.com/getProductMetaByKey',
    getProductMetaQuoteOnly: 'https://productapi.ampmhub.com/getProductMetaQuoteOnly',
    add_delete_product_properties: 'https://productapi.ampmhub.com/product/properties/add'
  },

  calender:[

  { 
    value: '1', 
    label: 'Today' 
  },
  { 
    value: '2', 
    label: 'Tomorrow' 
  },
  { 
    value: '3',
    label: 'In 2 business Days' 
  },
  { 
    value: '4', 
    label: 'In 3 business Days' 
  },
  { 
    value: '5', 
    label: 'In 1 week' 
  },
  { 
    value: '6', 
    label: 'In 2 weeks' 
  },
  { 
    value: '7', 
    label: 'In 1 month' 
  },
  { 
    value: '8', 
    label: 'In 3 months' 
  },
  { 
    value: '9', 
    label: 'In 6 months' 
  },
  { 
    value: '10', 
    label: 'Custom Date' 
  }
  ],
  companies:[
  {
    value: '', 
    label: 'All Company', 
    color: 'light-primary'
  },
  {
    value: '1', 
    label: 'AmPm Promo', 
    color: 'light-primary'
  },
  {
    value: '3', 
    label: 'Buy Promotional NZ', 
    color: 'light-primary'
  },
  {
    value: '9', 
    label: 'Buy Promotional AU', 
    color: 'light-success'
  }
  ],
  groups:[
  {
    value: '0', 
    label: 'All Group', 
    color: 'light-primary'
  },
  {
    value: '1', 
    label: 'AmPmPromo', 
    color: 'light-primary'
  },
  {
    value: '2', 
    label: 'Buy Promotional NZ', 
    color: 'light-success'
  },
  {
    value: '3', 
    label: 'Buy Promotional AU', 
    color: 'light-danger'
  },
  {
    value: '4', 
    label: 'Barfoot', 
    color: 'light-warning'
  }
  ],
  staff:[
  {
    value: '0', 
    label: 'All Staff' 
  },
  {
    value: '1', 
    label: 'Admin'
  },
  {
    value: '2', 
    label: 'Customer' 
  },
  {
    value: '3', 
    label: 'Client' 
  }
  ],
  payment:[
  {
    value: '0', 
    label: 'All payment', 
    color: 'light-primary'
  },
  {
    value: '1', 
    label: 'Payment Before Production', 
    color: 'light-primary'
  },
  {
    value: '2', 
    label: 'Pay within 7 Days', 
    color: 'light-success'
  },
  {
    value: '3', 
    label: 'Prepaid Web Purchase', 
    color: 'light-danger'
  },
  {
    value: '4', 
    label: '20th Following Month', 
    color: 'light-warning'
  },
  
  {
    value: '5', 
    label: 'Custom', 
    color: 'light-warning'
  }
  ],
  type:[
  {
    value: '0', 
    label: 'All Type' 
  },
  {
    value: '1', 
    label: 'A'
  },
  {
    value: '2', 
    label: 'B' 
  },
  {
    value: '3', 
    label: 'C' 
  }
  ],

  months:[
  {
    value: '01', 
    label: 'January' 
  },
  {
    value: '02', 
    label: 'February'
  },
  {
    value: '03', 
    label: 'March' 
  },
  {
    value: '04', 
    label: 'April' 
  },
  {
    value: '05', 
    label: 'May' 
  },
  {
    value: '06', 
    label: 'June'
  },
  {
    value: '07', 
    label: 'July' 
  },
  {
    value: '08', 
    label: 'August' 
  },
  {
    value: '09', 
    label: 'September' 
  },
  {
    value: '10', 
    label: 'October'
  },
  {
    value: '11', 
    label: 'November' 
  },
  {
    value: '12', 
    label: 'December' 
  }
  ],
  supplier:[
  {
    value: '', 
    label: 'All' 
  },
  {
    value: '1', 
    label: 'Trends' 
  },
  {
    value: '2', 
    label: 'Logoline'
  },
  {
    value: '3', 
    label: 'Orso'
  },
  {
    value: '4', 
    label: 'FashionBiz'
  },
  {
    value: '5', 
    label: 'JB Wear'
  },
  {
    value: '6', 
    label: 'Legend Life'
  },
  {
    value: '7', 
    label: 'Premium Catalogue - Apparel'
  },
  {
    value: '8', 
    label: 'C Force Media'
  },
  {
    value: '9', 
    label: 'The Catalogue - BMV'
  },
  {
    value: '10', 
    label: 'AS Colour'
  },
  {
    value: '11', 
    label: 'Promobrands'
  }
  ],
  suppliers_logo:[
  {
    id: '1', 
    logo: require('@src/assets/images/logo/trends_suppllier.png').default 
  },
  {
    id: '2', 
    logo: require('@src/assets/images/logo/logoline_suppllier.png').default
  },
  {
    id: '3', 
    logo: require('@src/assets/images/logo/orso_suppllier.png').default
  },
  {
    id: '4', 
    logo: require('@src/assets/images/logo/fashionsbiz_suppllier.png').default
  },
  {
    id: '5', 
    logo: require('@src/assets/images/logo/jb_wear_suppllier.jpg').default
  },
  {
    id: '6', 
    logo: require('@src/assets/images/logo/legend_life_suppllier.jpeg').default
  },
  {
    id: '7', 
    logo: require('@src/assets/images/logo/premium_apparel_suppllier.jpg').default
  },
  {
    id: '8', 
    logo: require('@src/assets/images/logo/cforce_suppllier.png').default
  },
  {
    id: '9', 
    logo: require('@src/assets/images/logo/The-Catalogue-logo.png').default
  },
  {
    id: '10', 
    logo: require('@src/assets/images/logo/ascolour-dot-logo-white.png').default
  },
  {
    id: '11', 
    logo: require('@src/assets/images/logo/promobrands.png').default
  }
  ],
  country:[
  {
    value: '', 
    label: 'All' 
  },
  {
    value: 'nz', 
    label: 'New Zealand' 
  },
  {
    value: 'au', 
    label: 'Australia'
  },
  {
    value: 'ca', 
    label: 'Canada'
  }
  ],
  productStatus:[
  {
    value: '', 
    label: 'All' 
  },
  {
    value: '0', 
    label: 'Pending' 
  },
  {
    value: '1', 
    label: 'Publish'
  },
  {
    value: '5', 
    label: 'Trash'
  }
  ],
  siteId:[
  {
    value: '1', 
    label: 'AMPM Promo NZ', 
    email: 'promo@ampm.co.nz' 
  },
  {
    value: '4', 
    label: 'AMPM Promo AU', 
    email: 'sales@ampmpromo.com.au' 
  },
  {
    value: '5', 
    label: 'AMPM Promo AU (Staging)', 
    email: 'sales@ampmpromo.com.au' 
  },
  {
    value: '2', 
    label: 'Buy Promotional NZ',
    email: 'sales@buypromotional.co.nz' 
  },
  {
    value: '3', 
    label: 'Buy Promotional AU',
    email: 'sales@buypromotional.co.au' 
  }
  ],
  maxColours : [
  { 
    value: '-1',
    label: 'N/A' 
  },
  { 
    value: 'full colours', 
    label: 'Full colours' 
  },
  { 
    value: '1', 
    label: '1' 
  },
  { 
    value: '2', 
    label: '2' 
  },
  { 
    value: '3', 
    label: '3' 
  }, 
  { 
    value: '4', 
    label: '4' 
  }, 
  { 
    value: '5', 
    label: '5' 
  }, 
  { 
    value: '6', 
    label: '6' 
  }, 
  { 
    value: '7', 
    label: '7' 
  }, 
  { 
    value: '8', 
    label: '8' 
  }, 
  { 
    value: '9', 
    label: '9' 
  }, 
  { 
    value: '10',
    label: '10' 
  }
  ],
  regionOptions:[

    { value: 'Northland', label: 'Northland' },
    { value: 'Auckland', label: 'Auckland' },
    { value: 'Waikato', label: 'Waikato' },
    { value: 'Bay of Plenty', label: 'Bay of Plenty' },
    { value: 'Taranaki', label: 'Taranaki' },
    { value: 'Gisborne', label: 'Gisborne' },
    { value: 'Hawke’s Bay', label: 'Hawke’s Bay' },
    { value: 'Manawatu-Wanganui', label: 'Manawatu-Wanganui' },
    { value: 'Wellington', label: 'Wellington' },
    { value: 'Nelson', label: 'Nelson' },
    { value: 'Marlborough', label: 'Marlborough' },
    { value: 'Tasman', label: 'Tasman' },
    { value: 'West Coast', label: 'West Coast' },
    { value: 'Canterbury', label: 'Canterbury' },
    { value: 'Otago', label: 'Otago' },
    { value: 'Southland', label: 'Southland' },
    // Australia region Name
    { value: 'Australian Capital Territory', label: 'Australian Capital Territory' },
    { value: 'New South Wales', label: 'New South Wales' },
    { value: 'Northern Territory', label: 'Northern Territory' },
    { value: 'Queensland', label: 'Queensland' },
    { value: 'South Australia', label: 'South Australia' },
    { value: 'Tasmania', label: 'Tasmania' },
    { value: 'Victoria', label: 'Victoria' },
    { value: 'Western Australia', label: 'Western Australia' }
    ],
// Country code select
  countryCode:[
  {
    code: 'AF', 
    code3: 'AFG', 
    label: 'Afghanistan', 
    value: '004'
  },
  {code: 'AL', code3: 'ALB', label: 'Albania', value: '+008'},
  {code: 'DZ', code3: 'DZA', label: 'Algeria', value: '+012'},
  {code: 'AS', code3: 'ASM', label: 'American Samoa', value: '+016'},
  {code: 'AD', code3: 'AND', label: 'Andorra', value: '+020'},
  {code: 'AO', code3: 'AGO', label: 'Angola', value: '+024'},
  {code: 'AI', code3: 'AIA', label: 'Anguilla', value: '+660'},
  {code: 'AQ', code3: 'ATA', label: 'Antarctica', value: '+010'},
  {code: 'AG', code3: 'ATG', label: 'Antigua and Barbuda', value: '+028'},
  {code: 'AR', code3: 'ARG', label: 'Argentina', value: '+032'},
  {code: 'AM', code3: 'ARM', label: 'Armenia', value: '+051'},
  {code: 'AW', code3: 'ABW', label: 'Aruba', value: '+533'},
  {code: 'AU', code3: 'AUS', label: 'Australia', value: '+036'},
  {code: 'AT', code3: 'AUT', label: 'Austria', value: '+040'},
  {code: 'AZ', code3: 'AZE', label: 'Azerbaijan', value: '+031'},
  {code: 'BS', code3: 'BHS', label: 'Bahamas (the)', value: '+044'},
  {code: 'BH', code3: 'BHR', label: 'Bahrain', value: '+048'},
  {code: 'BD', code3: 'BGD', label: 'Bangladesh', value: '+050'},
  {code: 'BB', code3: 'BRB', label: 'Barbados', value: '+052'},
  {code: 'BY', code3: 'BLR', label: 'Belarus', value: '+112'},
  {code: 'BE', code3: 'BEL', label: 'Belgium', value: '+056'},
  {code: 'BZ', code3: 'BLZ', label: 'Belize', value: '+084'},
  {code: 'BJ', code3: 'BEN', label: 'Benin', value: '+204'},
  {code: 'BM', code3: 'BMU', label: 'Bermuda', value: '+060'},
  {code: 'BT', code3: 'BTN', label: 'Bhutan', value: '+064'},
  {code: 'BO', code3: 'BOL', label: 'Bolivia (Plurinational State of)', value: '+068'},
  {code: 'BQ', code3: 'BES', label: 'Bonaire, Sint Eustatius and Saba', value: '+535'},
  {code: 'BA', code3: 'BIH', label: 'Bosnia and Herzegovina', value: '+070'},
  {code: 'BW', code3: 'BWA', label: 'Botswana', value: '+072'},
  {code: 'BV', code3: 'BVT', label: 'Bouvet Island', value: '+074'},
  {code: 'BR', code3: 'BRA', label: 'Brazil', value: '+076'},
  {code: 'IO', code3: 'IOT', label: 'British Indian Ocean Territory (the)', value: '+086'},
  {code: 'BN', code3: 'BRN', label: 'Brunei Darussalam', value: '+096'},
  {code: 'BG', code3: 'BGR', label: 'Bulgaria', value: '+100'},
  {code: 'BF', code3: 'BFA', label: 'Burkina Faso', value: '+854'},
  {code: 'BI', code3: 'BDI', label: 'Burundi', value: '+108'},
  {code: 'CV', code3: 'CPV', label: 'Cabo Verde', value: '+132'},
  {code: 'KH', code3: 'KHM', label: 'Cambodia', value: '+116'},
  {code: 'CM', code3: 'CMR', label: 'Cameroon', value: '+120'},
  {code: 'CA', code3: 'CAN', label: 'Canada', value: '+124'},
  {code: 'KY', code3: 'CYM', label: 'Cayman Islands (the)', value: '+136'},
  {code: 'CF', code3: 'CAF', label: 'Central African Republic (the)', value: '+140'},
  {code: 'TD', code3: 'TCD', label: 'Chad', value: '+148'},
  {code: 'CL', code3: 'CHL', label: 'Chile', value: '+152'},
  {code: 'CN', code3: 'CHN', label: 'China', value: '+156'},
  {code: 'CX', code3: 'CXR', label: 'Christmas Island', value: '+162'},
  {code: 'CC', code3: 'CCK', label: 'Cocos (Keeling) Islands (the)', value: '+166'},
  {code: 'CO', code3: 'COL', label: 'Colombia', value: '+170'},
  {code: 'KM', code3: 'COM', label: 'Comoros (the)', value: '+174'},
  {code: 'CD', code3: 'COD', label: 'Congo (the Democratic Republic of the)', value: '+180'},
  {code: 'CG', code3: 'COG', label: 'Congo (the)', value: '+178'},
  {code: 'CK', code3: 'COK', label: 'Cook Islands (the)', value: '+184'},
  {code: 'CR', code3: 'CRI', label: 'Costa Rica', value: '+188'},
  {code: 'HR', code3: 'HRV', label: 'Croatia', value: '+191'},
  {code: 'CU', code3: 'CUB', label: 'Cuba', value: '+192'},
  {code: 'CW', code3: 'CUW', label: 'Curaçao', value: '+531'},
  {code: 'CY', code3: 'CYP', label: 'Cyprus', value: '+196'},
  {code: 'CZ', code3: 'CZE', label: 'Czechia', value: '+203'},
  {code: 'CI', code3: 'CIV', label: "Côte d'Ivoire", value: '+384'},
  {code: 'DK', code3: 'DNK', label: 'Denmark', value: '+208'},
  {code: 'DJ', code3: 'DJI', label: 'Djibouti', value: '+262'},
  {code: 'DM', code3: 'DMA', label: 'Dominica', value: '+212'},
  {code: 'DO', code3: 'DOM', label: 'Dominican Republic (the)', value: '+214'},
  {code: 'EC', code3: 'ECU', label: 'Ecuador', value: '+218'},
  {code: 'EG', code3: 'EGY', label: 'Egypt', value: '+818'},
  {code: 'SV', code3: 'SLV', label: 'El Salvador', value: '+222'},
  {code: 'GQ', code3: 'GNQ', label: 'Equatorial Guinea', value: '+226'},
  {code: 'ER', code3: 'ERI', label: 'Eritrea', value: '+232'},
  {code: 'EE', code3: 'EST', label: 'Estonia', value: '+233'},
  {code: 'SZ', code3: 'SWZ', label: 'Eswatini', value: '+748'},
  {code: 'ET', code3: 'ETH', label: 'Ethiopia', value: '+231'},
  {code: 'FK', code3: 'FLK', label: 'Falkland Islands (the) [Malvinas]', value: '+238'},
  {code: 'FO', code3: 'FRO', label: 'Faroe Islands (the)', value: '+234'},
  {code: 'FJ', code3: 'FJI', label: 'Fiji', value: '+242'},
  {code: 'FI', code3: 'FIN', label: 'Finland', value: '+246'},
  {code: 'FR', code3: 'FRA', label: 'France', value: '+250'},
  {code: 'GF', code3: 'GUF', label: 'French Guiana', value: '+254'},
  {code: 'PF', code3: 'PYF', label: 'French Polynesia', value: '+258'},
  {code: 'TF', code3: 'ATF', label: 'French Southern Territories (the)', value: '+260'},
  {code: 'GA', code3: 'GAB', label: 'Gabon', value: '+266'},
  {code: 'GM', code3: 'GMB', label: 'Gambia (the)', value: '+270'},
  {code: 'GE', code3: 'GEO', label: 'Georgia', value: '+268'},
  {code: 'DE', code3: 'DEU', label: 'Germany', value: '+276'},
  {code: 'GH', code3: 'GHA', label: 'Ghana', value: '+288'},
  {code: 'GI', code3: 'GIB', label: 'Gibraltar', value: '+292'},
  {code: 'GR', code3: 'GRC', label: 'Greece', value: '+300'},
  {code: 'GL', code3: 'GRL', label: 'Greenland', value: '+304'},
  {code: 'GD', code3: 'GRD', label: 'Grenada', value: '+308'},
  {code: 'GP', code3: 'GLP', label: 'Guadeloupe', value: '+312'},
  {code: 'GU', code3: 'GUM', label: 'Guam', value: '+316'},
  {code: 'GT', code3: 'GTM', label: 'Guatemala', value: '+320'},
  {code: 'GG', code3: 'GGY', label: 'Guernsey', value: '+831'},
  {code: 'GN', code3: 'GIN', label: 'Guinea', value: '+324'},
  {code: 'GW', code3: 'GNB', label: 'Guinea-Bissau', value: '+624'},
  {code: 'GY', code3: 'GUY', label: 'Guyana', value: '+328'},
  {code: 'HT', code3: 'HTI', label: 'Haiti', value: '+332'},
  {code: 'HM', code3: 'HMD', label: 'Heard Island and McDonald Islands', value: '+334'},
  {code: 'VA', code3: 'VAT', label: 'Holy See (the)', value: '+336'},
  {code: 'HN', code3: 'HND', label: 'Honduras', value: '+340'},
  {code: 'HK', code3: 'HKG', label: 'Hong Kong', value: '+344'},
  {code: 'HU', code3: 'HUN', label: 'Hungary', value: '+348'},
  {code: 'IS', code3: 'ISL', label: 'Iceland', value: '+352'},
  {code: 'IN', code3: 'IND', label: 'India', value: '+356'},
  {code: 'ID', code3: 'IDN', label: 'Indonesia', value: '+360'},
  {code: 'IR', code3: 'IRN', label: 'Iran (Islamic Republic of)', value: '+364'},
  {code: 'IQ', code3: 'IRQ', label: 'Iraq', value: '+368'},
  {code: 'IE', code3: 'IRL', label: 'Ireland', value: '+372'},
  {code: 'IM', code3: 'IMN', label: 'Isle of Man', value: '+833'},
  {code: 'IL', code3: 'ISR', label: 'Israel', value: '+376'},
  {code: 'IT', code3: 'ITA', label: 'Italy', value: '+380'},
  {code: 'JM', code3: 'JAM', label: 'Jamaica', value: '+388'},
  {code: 'JP', code3: 'JPN', label: 'Japan', value: '+392'},
  {code: 'JE', code3: 'JEY', label: 'Jersey', value: '+832'},
  {code: 'JO', code3: 'JOR', label: 'Jordan', value: '+400'},
  {code: 'KZ', code3: 'KAZ', label: 'Kazakhstan', value: '+398'},
  {code: 'KE', code3: 'KEN', label: 'Kenya', value: '+404'},
  {code: 'KI', code3: 'KIR', label: 'Kiribati', value: '+296'},
  {code: 'KP', code3: 'PRK', label: "Korea (the Democratic People's Republic of)", value: '+408'},
  {code: 'KR', code3: 'KOR', label: 'Korea (the Republic of)', value: '+410'},
  {code: 'KW', code3: 'KWT', label: 'Kuwait', value: '+414'},
  {code: 'KG', code3: 'KGZ', label: 'Kyrgyzstan', value: '+417'},
  {code: 'LA', code3: 'LAO', label: "Lao People's Democratic Republic (the)", value: '+418'},
  {code: 'LV', code3: 'LVA', label: 'Latvia', value: '+428'},
  {code: 'LB', code3: 'LBN', label: 'Lebanon', value: '+422'},
  {code: 'LS', code3: 'LSO', label: 'Lesotho', value: '+426'},
  {code: 'LR', code3: 'LBR', label: 'Liberia', value: '+430'},
  {code: 'LY', code3: 'LBY', label: 'Libya', value: '+434'},
  {code: 'LI', code3: 'LIE', label: 'Liechtenstein', value: '+438'},
  {code: 'LT', code3: 'LTU', label: 'Lithuania', value: '+440'},
  {code: 'LU', code3: 'LUX', label: 'Luxembourg', value: '+442'},
  {code: 'MO', code3: 'MAC', label: 'Macao', value: '+446'},
  {code: 'MG', code3: 'MDG', label: 'Madagascar', value: '+450'},
  {code: 'MW', code3: 'MWI', label: 'Malawi', value: '+454'},
  {code: 'MY', code3: 'MYS', label: 'Malaysia', value: '+458'},
  {code: 'MV', code3: 'MDV', label: 'Maldives', value: '+462'},
  {code: 'ML', code3: 'MLI', label: 'Mali', value: '+466'},
  {code: 'MT', code3: 'MLT', label: 'Malta', value: '+470'},
  {code: 'MH', code3: 'MHL', label: 'Marshall Islands (the)', value: '+584'},
  {code: 'MQ', code3: 'MTQ', label: 'Martinique', value: '+474'},
  {code: 'MR', code3: 'MRT', label: 'Mauritania', value: '+478'},
  {code: 'MU', code3: 'MUS', label: 'Mauritius', value: '+480'},
  {code: 'YT', code3: 'MYT', label: 'Mayotte', value: '+175'},
  {code: 'MX', code3: 'MEX', label: 'Mexico', value: '+484'},
  {code: 'FM', code3: 'FSM', label: 'Micronesia (Federated States of)', value: '+583'},
  {code: 'MD', code3: 'MDA', label: 'Moldova (the Republic of)', value: '+498'},
  {code: 'MC', code3: 'MCO', label: 'Monaco', value: '+492'},
  {code: 'MN', code3: 'MNG', label: 'Mongolia', value: '+496'},
  {code: 'ME', code3: 'MNE', label: 'Montenegro', value: '+499'},
  {code: 'MS', code3: 'MSR', label: 'Montserrat', value: '+500'},
  {code: 'MA', code3: 'MAR', label: 'Morocco', value: '+504'},
  {code: 'MZ', code3: 'MOZ', label: 'Mozambique', value: '+508'},
  {code: 'MM', code3: 'MMR', label: 'Myanmar', value: '+104'},
  {code: 'NA', code3: 'NAM', label: 'Namibia', value: '+516'},
  {code: 'NR', code3: 'NRU', label: 'Nauru', value: '+520'},
  {code: 'NP', code3: 'NPL', label: 'Nepal', value: '+524'},
  {code: 'NL', code3: 'NLD', label: 'Netherlands (the)', value: '+528'},
  {code: 'NC', code3: 'NCL', label: 'New Caledonia', value: '+540'},
  {code: 'NZ', code3: 'NZL', label: 'New Zealand', value: '+554'},
  {code: 'NI', code3: 'NIC', label: 'Nicaragua', value: '+558'},
  {code: 'NE', code3: 'NER', label: 'Niger (the)', value: '+562'},
  {code: 'NG', code3: 'NGA', label: 'Nigeria', value: '+566'},
  {code: 'NU', code3: 'NIU', label: 'Niue', value: '+570'},
  {code: 'NF', code3: 'NFK', label: 'Norfolk Island', value: '+574'},
  {code: 'MP', code3: 'MNP', label: 'Northern Mariana Islands (the)', value: '+580'},
  {code: 'NO', code3: 'NOR', label: 'Norway', value: '+578'},
  {code: 'OM', code3: 'OMN', label: 'Oman', value: '+512'},
  {code: 'PK', code3: 'PAK', label: 'Pakistan', value: '+586'},
  {code: 'PW', code3: 'PLW', label: 'Palau', value: '+585'},
  {code: 'PS', code3: 'PSE', label: 'Palestine, State of', value: '+275'},
  {code: 'PA', code3: 'PAN', label: 'Panama', value: '+591'},
  {code: 'PG', code3: 'PNG', label: 'Papua New Guinea', value: '+598'},
  {code: 'PY', code3: 'PRY', label: 'Paraguay', value: '+600'},
  {code: 'PE', code3: 'PER', label: 'Peru', value: '+604'},
  {code: 'PH', code3: 'PHL', label: 'Philippines (the)', value: '+608'},
  {code: 'PN', code3: 'PCN', label: 'Pitcairn', value: '+612'},
  {code: 'PL', code3: 'POL', label: 'Poland', value: '+616'},
  {code: 'PT', code3: 'PRT', label: 'Portugal', value: '+620'},
  {code: 'PR', code3: 'PRI', label: 'Puerto Rico', value: '+630'},
  {code: 'QA', code3: 'QAT', label: 'Qatar', value: '+634'},
  {code: 'MK', code3: 'MKD', label: 'Republic of North Macedonia', value: '+807'},
  {code: 'RO', code3: 'ROU', label: 'Romania', value: '+642'},
  {code: 'RU', code3: 'RUS', label: 'Russian Federation (the)', value: '+643'},
  {code: 'RW', code3: 'RWA', label: 'Rwanda', value: '+646'},
  {code: 'RE', code3: 'REU', label: 'Réunion', value: '+638'},
  {code: 'BL', code3: 'BLM', label: 'Saint Barthélemy', value: '+652'},
  {code: 'SH', code3: 'SHN', label: 'Saint Helena, Ascension and Tristan da Cunha', value: '+654'},
  {code: 'KN', code3: 'KNA', label: 'Saint Kitts and Nevis', value: '+659'},
  {code: 'LC', code3: 'LCA', label: 'Saint Lucia', value: '+662'},
  {code: 'MF', code3: 'MAF', label: 'Saint Martin (French part)', value: '+663'},
  {code: 'PM', code3: 'SPM', label: 'Saint Pierre and Miquelon', value: '+666'},
  {code: 'VC', code3: 'VCT', label: 'Saint Vincent and the Grenadines', value: '+670'},
  {code: 'WS', code3: 'WSM', label: 'Samoa', value: '+882'},
  {code: 'SM', code3: 'SMR', label: 'San Marino', value: '+674'},
  {code: 'ST', code3: 'STP', label: 'Sao Tome and Principe', value: '+678'},
  {code: 'SA', code3: 'SAU', label: 'Saudi Arabia', value: '+682'},
  {code: 'SN', code3: 'SEN', label: 'Senegal', value: '+686'},
  {code: 'RS', code3: 'SRB', label: 'Serbia', value: '+688'},
  {code: 'SC', code3: 'SYC', label: 'Seychelles', value: '+690'},
  {code: 'SL', code3: 'SLE', label: 'Sierra Leone', value: '+694'},
  {code: 'SG', code3: 'SGP', label: 'Singapore', value: '+702'},
  {code: 'SX', code3: 'SXM', label: 'Sint Maarten (Dutch part)', value: '+534'},
  {code: 'SK', code3: 'SVK', label: 'Slovakia', value: '+703'},
  {code: 'SI', code3: 'SVN', label: 'Slovenia', value: '+705'},
  {code: 'SB', code3: 'SLB', label: 'Solomon Islands', value: '+090'},
  {code: 'SO', code3: 'SOM', label: 'Somalia', value: '+706'},
  {code: 'ZA', code3: 'ZAF', label: 'South Africa', value: '+710'},
  {code: 'GS', code3: 'SGS', label: 'South Georgia and the South Sandwich Islands', value: '+239'},
  {code: 'SS', code3: 'SSD', label: 'South Sudan', value: '+728'},
  {code: 'ES', code3: 'ESP', label: 'Spain', value: '+724'},
  {code: 'LK', code3: 'LKA', label: 'Sri Lanka', value: '+144'},
  {code: 'SD', code3: 'SDN', label: 'Sudan (the)', value: '+729'},
  {code: 'SR', code3: 'SUR', label: 'Suriname', value: '+740'},
  {code: 'SJ', code3: 'SJM', label: 'Svalbard and Jan Mayen', value: '+744'},
  {code: 'SE', code3: 'SWE', label: 'Sweden', value: '+752'},
  {code: 'CH', code3: 'CHE', label: 'Switzerland', value: '+756'},
  {code: 'SY', code3: 'SYR', label: 'Syrian Arab Republic', value: '+760'},
  {code: 'TW', code3: 'TWN', label: 'Taiwan', value: '+158'},
  {code: 'TJ', code3: 'TJK', label: 'Tajikistan', value: '+762'},
  {code: 'TZ', code3: 'TZA', label: 'Tanzania, United Republic of', value: '+834'},
  {code: 'TH', code3: 'THA', label: 'Thailand', value: '+764'},
  {code: 'TL', code3: 'TLS', label: 'Timor-Leste', value: '+626'},
  {code: 'TG', code3: 'TGO', label: 'Togo', value: '+768'},
  {code: 'TK', code3: 'TKL', label: 'Tokelau', value: '+772'},
  {code: 'TO', code3: 'TON', label: 'Tonga', value: '+776'},
  {code: 'TT', code3: 'TTO', label: 'Trinidad and Tobago', value: '+780'},
  {code: 'TN', code3: 'TUN', label: 'Tunisia', value: '+788'},
  {code: 'TR', code3: 'TUR', label: 'Turkey', value: '+792'},
  {code: 'TM', code3: 'TKM', label: 'Turkmenistan', value: '+795'},
  {code: 'TC', code3: 'TCA', label: 'Turks and Caicos Islands (the)', value: '+796'},
  {code: 'TV', code3: 'TUV', label: 'Tuvalu', value: '+798'},
  {code: 'UG', code3: 'UGA', label: 'Uganda', value: '+800'},
  {code: 'UA', code3: 'UKR', label: 'Ukraine', value: '+804'},
  {code: 'AE', code3: 'ARE', label: 'United Arab Emirates (the)', value: '+784'},
  {code: 'GB', code3: 'GBR', label: 'United Kingdom of Great Britain and Northern Ireland (the)', value: '+826'},
  {code: 'UM', code3: 'UMI', label: 'United States Minor Outlying Islands (the)', value: '+581'},
  {code: 'US', code3: 'USA', label: 'United States of America (the)', value: '+840'},
  {code: 'UY', code3: 'URY', label: 'Uruguay', value: '+858'},
  {code: 'UZ', code3: 'UZB', label: 'Uzbekistan', value: '+860'},
  {code: 'VU', code3: 'VUT', label: 'Vanuatu', value: '+548'},
  {code: 'VE', code3: 'VEN', label: 'Venezuela (Bolivarian Republic of)', value: '+862'},
  {code: 'VN', code3: 'VNM', label: 'Viet Nam', value: '+704'},
  {code: 'VG', code3: 'VGB', label: 'Virgin Islands (British)', value: '+092'},
  {code: 'VI', code3: 'VIR', label: 'Virgin Islands (U.S.)', value: '+850'},
  {code: 'WF', code3: 'WLF', label: 'Wallis and Futuna', value: '+876'},
  {code: 'EH', code3: 'ESH', label: 'Western Sahara', value: '+732'},
  {code: 'YE', code3: 'YEM', label: 'Yemen', value: '+887'},
  {code: 'ZM', code3: 'ZMB', label: 'Zambia', value: '+894'},
  {code: 'ZW', code3: 'ZWE', label: 'Zimbabwe', value: '+716'},
  {code: 'AX', code3: 'ALA', label: 'Åland Islands', value: '+248'}
  ],
layout: {
  isRTL: false,
    skin: 'light', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'horizontal', // vertical, horizontal
    contentWidth: 'boxed', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button
  }
}

export default themeConfig
