// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Config
import themeConfig from '@configs/themeConfig'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('reminders/getData', async params => {
  console.log('Data Fetch from the ::: Customer Reminder List')

  params['token'] = themeConfig.APILinks.clienttoken
  const response = await axios.post(themeConfig.APILinks.reminders_list, params)
  return { reminders: response.data.reminders, all_reminders: response.data.reminders, total: response.data.total, params }
})

export let data

export const datatablesSlice = createSlice({
  name: 'reminders',
  initialState: {
    reminders: [],
    all_reminders: [],
    total: 1,
    params: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.reminders = action.payload.reminders
      state.all_reminders = action.payload.all_reminders
      state.params = action.payload.params
      state.total = action.payload.total
    })
  }
})

export default datatablesSlice.reducer
