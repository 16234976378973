// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Config
import themeConfig from '@configs/themeConfig'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('productcats/getData', async params => {
  console.log('Data Fetch from the ::: Product Categories')
  
 params['token'] = themeConfig.APILinks.producttoken
  //params['action'] = 'categories'
  //console.log(params)
  const response = await axios.post(themeConfig.APILinks.productcats, params)
  //console.log(response)
  return { categories: response.data.all_categories, all_categories: response.data.categories, total: response.data.total, params }
})

export let data

export const datatablesSlice = createSlice({
  name: 'productcats',
  initialState: {
    categories: [],
    all_categories: [],
    total: 1,
    params: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.categories = action.payload.categories
      state.all_categories = action.payload.all_categories
      state.params = action.payload.params
      state.total = action.payload.total
    })
  }
})

export default datatablesSlice.reducer
