// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Config
import themeConfig from '@configs/themeConfig'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('getGroupDetails/getData', async params => {

  //console.log('Data Fetch from the ::: Group Details')
  
  params['token'] = themeConfig.APILinks.clienttoken
  const response = await axios.post(themeConfig.APILinks.showGroup, params)
  
  return { name: response.data.group.name, type: response.data.group.type, id: response.data.group.id, params }
})

export let data

export const datatablesSlice = createSlice({
  name: 'getGroupDetails',
  initialState: {
    name: '',
    type: '',
    id: ''
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.name = action.payload.name
      state.params = action.payload.params
      state.type = action.payload.type
      state.id = action.payload.id
    })
  }
})

export default datatablesSlice.reducer
