// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Config
import themeConfig from '@configs/themeConfig'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('ToolData/getData', async params => {
  console.log('Data Fetch from the :: Supplier')
  params['token'] = themeConfig.APILinks.token
  const response = await axios.post(themeConfig.APILinks.trendsIndex, params)
  //console.log(params)
  //console.log(response)
  return { allData: response.data.allData, data: response.data.trend.data, totalPages: response.data.trend.total, params }
  
})

export const datatablesSlice = createSlice({
  name: 'ToolData',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      //console.log(action)
      state.data = action.payload.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
    })
  }
})

export default datatablesSlice.reducer
