// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Config
import themeConfig from '@configs/themeConfig'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('deliveryDaysMapping/getData', async params => {
  console.log('Data Fetch from the ::: AMPM DeliveryDays Mapping')
  
  params['token'] = themeConfig.APILinks.producttoken
  //console.log(params)
  const response = await axios.post(themeConfig.APILinks.productDeliveryDays, params)
  //console.log(response)
  return { decoration_type: response.data.decoration_type, total: response.data.total, deliverydays: response.data.deliverydays, params }
})

export let data

export const datatablesSlice = createSlice({
  name: 'deliveryDaysMapping',
  initialState: {
    decoration_type: [],
    deliverydays: [],
    total: 1,
    params: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.decoration_type = action.payload.decoration_type
      state.deliverydays = action.payload.deliverydays
      state.params = action.payload.params
      state.total = action.payload.total
    })
  }
})

export default datatablesSlice.reducer
