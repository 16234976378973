// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Config
import themeConfig from '@configs/themeConfig'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('getCustomerDetails/getData', async params => {

  console.log('Data Fetch from the ::: Customer Details')
  
  params['token'] = themeConfig.APILinks.clienttoken
  const response = await axios.post(themeConfig.APILinks.showClient, params)
  return { company_id: response.data.client.company_id, payment_id: response.data.client.payment_id, staff_id: response.data.client.staff_id, first_name: response.data.client.first_name, last_name: response.data.client.last_name, birthdate: response.data.client.birthdate, phone_no: response.data.client.phone_no, mobile_no: response.data.client.mobile_no, email: response.data.client.email, status: response.data.client.status, total_order_no: response.data.client.total_order_no, last_order_date: response.data.client.last_order_date, source: response.data.client.source, id: response.data.client.id, params }
})

  export let data

  export const datatablesSlice = createSlice({
    name: 'getCustomerDetails',
    initialState: {
      company_id: '',
      payment_id: '',
      staff_id: '',
      first_name: '',
      last_name: '',
      birthdate: '',
      phone_no: '',
      mobile_no: '',
      email: '',
      status: '',
      total_order_no: '',
      last_order_date: '',
      source: '',
      id: ''
    },
    reducers: {},
    extraReducers: builder => {
      builder.addCase(getData.fulfilled, (state, action) => {
        state.company_id = action.payload.company_id
        state.params = action.payload.params
        state.payment_id = action.payload.payment_id
        state.staff_id = action.payload.staff_id
        state.first_name = action.payload.first_name
        state.last_name = action.payload.last_name
        state.birthdate = action.payload.birthdate
        state.phone_no = action.payload.phone_no
        state.mobile_no = action.payload.mobile_no
        state.email = action.payload.email
        state.status = action.payload.status
        state.total_order_no = action.payload.total_order_no
        state.last_order_date = action.payload.last_order_date
        state.source = action.payload.source
        state.id = action.payload.id
      })
    }
  })

export default datatablesSlice.reducer
