// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Config
import themeConfig from '@configs/themeConfig'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('customerrules/getData', async params => {
  console.log('Data Fetch from the ::: Customer Rule List')
  const siteid = JSON.parse(localStorage.getItem('site_id'))
  params['site_id'] = siteid
  params['token'] = themeConfig.APILinks.token
  const response = await axios.post(themeConfig.APILinks.get_customers_rules, params)
  //console.log(response)
  return { rules: response.data.rules, all_rules: response.data.rules, total: response.data.total, params }
})

export let data

export const datatablesSlice = createSlice({
  name: 'customerrules',
  initialState: {
    rules: [],
    all_rules: [],
    total: 1,
    params: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.rules = action.payload.rules
      state.all_rules = action.payload.all_rules
      state.params = action.payload.params
      state.total = action.payload.total
    })
  }
})

export default datatablesSlice.reducer
