// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Config
import themeConfig from '@configs/themeConfig'
// import {useLocation} from 'react-router-dom'
// ** Axios Imports
import axios from 'axios'
 
export const getData = createAsyncThunk('getCompanyCustomerList/getData', async params => {
  // const search = useLocation().search
  
  //console.log('Data Fetch from the ::: Company Customer LIST')

  // const cid = new URLSearchParams(search).get('cid')  
  // console.log(cid)
  params['token'] = themeConfig.APILinks.companytoken
  // params['company_id'] = cid

  const response = await axios.post(themeConfig.APILinks.getCustomerOfCompany, params)

  // console.log(response)
  return { allData: response.data.allData, data: response.data.customers.data, totalPages: response.data.customers.total, params }

})

export let data

export const datatablesSlice = createSlice({
  name: 'getCompanyCustomerList',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
    })
  }
})

export default datatablesSlice.reducer
