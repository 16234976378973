// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Config
import themeConfig from '@configs/themeConfig'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('todayTask/getData', async params => {
  //console.log('Data Fetch from the ::: AMPM today task')

const userData = JSON.parse(localStorage.getItem('userData'))

params['token'] = themeConfig.APILinks.token
params['assign_to'] = userData.id
params['condition'] = 'today'

  const response = await axios.post(themeConfig.APILinks.CurrentUserTask, params)
  //console.log(response)
  return { notes_today: response.data.today_notes, total: response.data.total, params }
})

export let data

export const datatablesSlice = createSlice({
  name: 'todayTask',
  initialState: {
    notes_today: [],
    total: 1,
    params: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.notes_today = action.payload.notes_today
      state.params = action.payload.params
      state.total = action.payload.total
    })
  }
})

export default datatablesSlice.reducer
