// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Config
import themeConfig from '@configs/themeConfig'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('brandingMapping/getData', async params => {
  console.log('Data Fetch from the ::: AMPM Branding')
  
  params['token'] = themeConfig.APILinks.producttoken
  //console.log(params)
  const response = await axios.post(themeConfig.APILinks.productBrandingList, params)
  //console.log(response)
  return { branding_list: response.data.branding_list, branding_per_page: response.data.branding_per_page, desc_list: response.data.desc_list, total: response.data.total, area_not_map: response.data.area_not_map, colours_not_map: response.data.colours_not_map, days_not_map: response.data.days_not_map, params }
})

export let data

export const datatablesSlice = createSlice({
  name: 'brandingMapping',
  initialState: {
    branding_list: [],
    branding_per_page: [],
    desc_list: [],
    area_not_map: 0,
    colours_not_map: 0,
    days_not_map: 0,
    total: 1,
    params: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.branding_list = action.payload.branding_list
      state.branding_per_page = action.payload.branding_per_page
      state.desc_list = action.payload.desc_list
      state.area_not_map = action.payload.area_not_map
      state.colours_not_map = action.payload.colours_not_map
      state.days_not_map = action.payload.days_not_map
      state.params = action.payload.params
      state.total = action.payload.total
    })
  }
})

export default datatablesSlice.reducer
