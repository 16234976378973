// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const datatablesSlice = createSlice({
  name: 'datatables',
  initialState: [],
  reducers: {
    add(state, { payload }) {
      state.push(payload)
    }
  }
})

export const { add } = datatablesSlice.actions

export default datatablesSlice.reducer
