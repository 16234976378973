// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Config
import themeConfig from '@configs/themeConfig'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('studioMapping/getData', async params => {
  console.log('Data Fetch from the ::: AMPM Studio Branding')
  
  params['token'] = themeConfig.APILinks.studiotoken
  
  const response = await axios.post(themeConfig.APILinks.studioBrandingList, params)
  console.log(response)
  return {
    branding_list: response.data.branding_list, 
    branding_per_page: response.data.branding_per_page, 
    branding_loader: response.data.loader, 
    desc_list: response.data.desc_list, 
    total: response.data.total, 
    area_not_map: response.data.area_not_map, 
    ready_mockup: response.data.total_ready_mockup, 
    todo_mockup: response.data.todo_ready_mockup, 
    draft_mockup : response.data.total_draft_mockup,
    complated_mockup: response.data.total_complated_mockup, 
    unchecked_mockup: response.data.total_unchecked_mockup, 
    not_ready_mockup: response.data.total_mockup_not_ready, 
    all_mockup: response.data.all_mockups, 
    colours_not_map: response.data.colours_not_map, 
    days_not_map: response.data.days_not_map,
    params
  }
})

export let data

export const datatablesSlice = createSlice({
  name: 'studioMapping',
  initialState: {
    branding_list: [],
    branding_loader: 0,
    branding_per_page: [],
    desc_list: [],
    area_not_map: 0,
    colours_not_map: 0,
    days_not_map: 0,
    total: 1,
    ready_mockup : 0,
    todo_mockup : 0,
    draft_mockup : 0,
    complated_mockup : 0,
    unchecked_mockup : 0,
    not_ready_mockup : 0,
    all_mockup : 0,
    params: {},
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
   .addCase(getData.pending, (state) => {
        state.loading = true;
        state.error = null;
    })    
    .addCase(getData.fulfilled, (state, action) => {
      state.loading = false;
      state.branding_list = action.payload.branding_list      
      state.branding_per_page = action.payload.branding_per_page
      state.branding_loader = action.payload.branding_loader
      state.desc_list = action.payload.desc_list
      state.area_not_map = action.payload.area_not_map
      state.colours_not_map = action.payload.colours_not_map
      state.days_not_map = action.payload.days_not_map
      state.params = action.payload.params
      state.total = action.payload.total
      state.ready_mockup = action.payload.ready_mockup
      state.todo_mockup = action.payload.todo_mockup
      state.draft_mockup = action.payload.draft_mockup
      state.complated_mockup = action.payload.complated_mockup
      state.unchecked_mockup = action.payload.unchecked_mockup
      state.not_ready_mockup = action.payload.not_ready_mockup
      state.all_mockup = action.payload.all_mockup
    })
    .addCase(getData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
    });
  }
})

export default datatablesSlice.reducer
