// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Config
import themeConfig from '@configs/themeConfig'


// ** Axios Imports
import axios from 'axios'

//** ComponentDidMount
  
export const getData = createAsyncThunk('dupclientlist/getData', async params => {
 
  console.log('Data Fetch from the ::: Duplicate Client List')
  params['token'] = themeConfig.APILinks.clienttoken
  params['site_id'] = JSON.parse(localStorage.getItem('site_id'))
  const response = await axios.post(themeConfig.APILinks.duplicate_client, params)
  //console.log(response)
  return { allData: response.data.allData, data: response.data.client.data, client_list: response.data.client_list, totalPages: response.data.client.total, params }
})

export let data

export const datatablesSlice = createSlice({
  name: 'dupclientlist',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    client_list: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.allData = action.payload.allData
      state.client_list = action.payload.client_list
      state.total = action.payload.totalPages
    })
  }
})

export default datatablesSlice.reducer
