// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Config
import themeConfig from '@configs/themeConfig'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('getCompanyDetails/getData', async params => {

  console.log('Data Fetch from the ::: Company Details')
  
  params['token'] = themeConfig.APILinks.clienttoken
  const response = await axios.post(themeConfig.APILinks.showCompany, params)
  
  return { name: response.data.company.name, staff: response.data.company.staff, payment: response.data.company.payment, type: response.data.company.type, website: response.data.company.website, group: response.data.company.group, status: response.data.company.status, id: response.data.company.id, params }
})

export let data

export const datatablesSlice = createSlice({
  name: 'getCompanyDetails',
  initialState: {
    name: '',
    staff: '',
    payment: '',
    type: '',
    website: '',
    group: '',
    status: '',
    id: ''
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.name = action.payload.name
      state.params = action.payload.params
      state.type = action.payload.type
      state.staff = action.payload.staff
      state.payment = action.payload.payment
      state.website = action.payload.website
      state.group = action.payload.group
      state.status = action.payload.status
      state.id = action.payload.id
    })
  }
})

export default datatablesSlice.reducer
