// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Config
import themeConfig from '@configs/themeConfig'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('completedtask/getData', async params => {
  //console.log('Data Fetch from the ::: AMPM completed task')

const userData = JSON.parse(localStorage.getItem('userData'))

params['token'] = themeConfig.APILinks.token
params['assign_to'] = userData.id
params['condition'] = 'completed'

  const response = await axios.post(themeConfig.APILinks.CurrentUserTask, params)
  //console.log(response)
  return { completednotes: response.data.completed_notes, total: response.data.total, params }
})

export let data

export const datatablesSlice = createSlice({
  name: 'completedtask',
  initialState: {
    completednotes: [],
    total: 1,
    params: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.completednotes = action.payload.completednotes
      state.params = action.payload.params
      state.total = action.payload.total
    })
  }
})

export default datatablesSlice.reducer
