// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import supllierTolos from '@src/views/suppliertools/data-tables/store'
import JobLists from '@src/views/joblist/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import tools from '@src/views/compare-statements/data-tables/store'
import logs from '@src/views/log/data-tables/store'
import clientList from '@src/views/clientlist/store'
import dupclientlist from '@src/views/duplicate_clientlist/store'
import addCustomerrule from '@src/views/addCustomerrule/store'
import customerrules from '@src/views/customerrules/store'
import companyList from '@src/views/companylist/store'
import groupList from '@src/views/grouplist/store'
import groupDetails from '@src/views/groupedit/store'
import companyDetails from '@src/views/companyedit/store'
import customerDetails from '@src/views/customeredit/store'
import companyCustomerDetails from '@src/views/companyview/store'
import OrderCustomerDetails from '@src/views/customerview/store'
import OrderCustomerDetailsQuote from '@src/views/customerview/storeQuote'
import OrderCompanyDetailsQuote from '@src/views/companyview/storeQuote'
import OrderCompanyDetails from '@src/views/companyview/storeOrder'
import ProductsList from '@src/views/productslist/store'
import Productcats from '@src/views/productcats/store'
import ProductsColours from '@src/views/productscolours/store'
import ProductsStockColours from '@src/views/productstockcolours/store'
import Ampmcats from '@src/views/ampmcats/store'
import Ampmtags from '@src/views/ampmtags/store'
import Properties from '@src/views/propertiescats/store'
import overdueNotes from '@src/views/clientdashboard/overduestore'
import upcomingNotes from '@src/views/clientdashboard/upcomingstore'
import completedNotes from '@src/views/clientdashboard/completedstore'
import todayNotes from '@src/views/clientdashboard/todaystore'
import allNotes from '@src/views/clientdashboard/allstore'
import BrandingMapping from '@src/views/brandingmapping/store'
import StudioMapping from '@src/views/studiomapping/store'
import DeliveryDays from '@src/views/brandingdays/store'
import BrandingType from '@src/views/brandingtype/store'
import BrandingDaysMapping from '@src/views/brandingdaysmapping/store'
import ProductLogs from '@src/views/productlogs/store'
import Customereminder from '@src/views/customereminder/store'

const rootReducer = {
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  supllierTolos,
  JobLists,
  tools,
  logs,
  clientList,
  companyList,
  groupList,
  groupDetails,
  companyDetails,
  customerDetails,
  companyCustomerDetails,
  OrderCustomerDetails,
  OrderCustomerDetailsQuote,
  OrderCompanyDetailsQuote,
  OrderCompanyDetails,
  ProductsList,
  Productcats,
  Ampmcats,
  overdueNotes,
  upcomingNotes,
  completedNotes,
  todayNotes,
  allNotes,
  Ampmtags,
  Properties,
  ProductsColours,
  ProductsStockColours,
  BrandingMapping,
  DeliveryDays,
  BrandingType,
  BrandingDaysMapping,
  StudioMapping,
  ProductLogs,
  dupclientlist,
  addCustomerrule,
  customerrules,
  Customereminder
}

export default rootReducer
